import { tailwind } from '@theme-ui/preset-tailwind';
import base from '@lekoarts/gatsby-theme-minimal-blog/src/gatsby-plugin-theme-ui';

const theme = {
	...base,
	colors: {
		...base.colors,
		tagCloud: tailwind.colors.gray[2],
		aside: tailwind.colors.gray[2],

		modes: {
			...base.colors?.modes,
			dark: {
				...base.colors?.modes?.dark,
				tagCloud: tailwind.colors.black,
				aside: tailwind.colors.black,
			},
		},
	},
	fonts: {
		...base.fonts,
		body: `"IBM Plex Sans", ${base.fonts?.body}`,
	},
	styles: {
		...base.styles,
		root: {
			...base.styles?.root,

			aside: {
				...base.styles?.root?.aside,
				bg: 'aside',

				px: ['1em', '2em'],
				py: ['0.5em', '1em'],
				mx: [0, '-2em'],
				my: ['1em', '2em'],
				borderRadius: '1em',
			},
		},
	},

	images: {
		...base.images,
		hero: {
			width: 'screenWidth',
			position: 'absolute',
			objectFit: 'cover',
			objectPosition: 'center',
			left: 0,
			height: '30vh',
		},
	},

	layout: {
		...base.layout,
		hero: {
			height: '30vh',
			mt: ['-2rem', '-4rem'],
		},
	},

	links: {
		...base.links,
		nav: {
			px: 2,
			py: 1,
			// textTransform: 'uppercase',
			letterSpacing: '0.2em',
			'&[disabled]': {
				opacity: 0.3,
				pointerEvents: 'none',
			},
		},
		tagCloud: {
			fontSize: [1, 2, 3],
			color: 'text',
			borderRadius: 5,
			bg: 'tagCloud',
			mr: 2,
			pl: 2,
			pr: 2,
		},
	},
};

export default theme;
